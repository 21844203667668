import styles from "./Counter.module.scss";
import React, { useContext, useEffect, useState, useRef } from 'react';
import { ScoreContext } from '../contexts/score-context';
import gsap from 'gsap';
import { playSfxDing } from '../audio/audio';

export const Counter = () => {
  const scoreContext = useContext(ScoreContext);
  const [oldScore, setOldScore] = useState(scoreContext.score);
  const displayNumber = useRef(null);
  const displayContainer = useRef(null);

  useEffect(() => {
    if (scoreContext.score > oldScore) {
      playSfxDing(true);
      gsap.fromTo(displayNumber.current,
        { scale: 2, color: "white", duration: 0.05 },
        { scale: 1, color: "black", duration: 0.25 }
      );
      gsap.fromTo(displayContainer.current,
        { background: "#40c020", duration: 0.05 },
        { background: "white", duration: 0.45 }
      );
    }
    setOldScore(scoreContext.score);
  }, [scoreContext.score, oldScore])

  return <>
    {scoreContext.score > 0 &&
      <div className={styles.container} ref={displayContainer}>
        <span className={styles.text} ref={displayNumber}>
          {scoreContext.score}
        </span>
      </div>
    }
  </>
}
