import boingSfx from './boing.mp3';
import dingSfx from './dingDoubleFast.mp3'

export const cacheSounds = {
  [boingSfx]: new Audio(boingSfx),
  [dingSfx]: new Audio(dingSfx),
}

export const playSfxBoing = (canPlay: boolean) => {
  playSfx(boingSfx, canPlay);
}

export const playSfxDing = (canPlay: boolean) => {
  playSfx(dingSfx, canPlay, 0.5);
}

export const playSfx = (audioFile: string, canPlay: boolean, volume: number = 1.0) => {
  if (!canPlay) return;
  if (cacheSounds[audioFile] == null) return;

  const audio = cacheSounds[audioFile];
  //reset audio
  audio.pause();
  audio.currentTime = 0;
  audio.volume = volume;

  //play audio from start again
  audio.play();
}