import React, { useEffect, useState, useRef, useContext } from 'react';
import './FlyingObject.scss';
import gsap, {Power0} from 'gsap';
import { debounce } from '../utilities';
import { ScoreContext } from '../contexts/score-context';

type FlyingProps = {
  verticalPosition: string;
}

export const FlyingObject = (props: FlyingProps) => {
  const elDiv = useRef(null);
  const scoreContext = useContext(ScoreContext);
  const [canGivePoints, setCanGivePoints] = useState(true);
  
  const doClicked = () => {
    canGivePoints && scoreContext.incrementScore();
    setCanGivePoints(false);
    gsap.to(elDiv.current,
      {
        scaleY: 3, opacity: 0, duration: 0.25,
        onComplete: () => {gsap.killTweensOf(elDiv.current)},
      }
    );
  }

  const applyFlyByTween = () => {
    setCanGivePoints(true);

    const getDuration = () => {
      const defaultDuration = Math.random()*2+1; // For 400px width
      return defaultDuration * window.innerWidth/400;
    }
    
    const currentProgress = gsap.getTweensOf(elDiv.current)[0]?.progress() || 0;

    gsap.killTweensOf(elDiv.current);

    gsap.fromTo(elDiv.current
      , {left: "130%", ease: Power0.easeInOut}
      , {left: "-30%", duration: getDuration, ease: Power0.easeInOut
        , onRepeat: () => {gsap.to(elDiv.current, {opacity: 1, scaleY: 1, duration: getDuration()})}
      })
      .progress(currentProgress)
      .delay(Math.random()*3)
      .repeat(-1)
      .repeatDelay(Math.random());
  }

  useEffect(() => {
    applyFlyByTween();

    const doResize = debounce(() => {
      applyFlyByTween();
    }, 500);

    const handleResize = () => {
      doResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <div className="flying-object" 
      style={{top: props.verticalPosition}} 
      ref={elDiv}
      onClick={doClicked}
      />
  )
}