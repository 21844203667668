import React, { useState, createContext } from "react";

export const ScoreContext = createContext({
  score: 0,
  incrementScore: () => {},
  resetScore: () => {},
});

type ScoreContextProviderType = {
  children: React.ReactChild
};

export const ScoreContextProvider = (props: ScoreContextProviderType) => {
  const [score, setScore] = useState(0);

  const incrementScore = () => setScore(score + 1);
  const resetScore = () => setScore(0);

  return <ScoreContext.Provider value={{
    score, incrementScore, resetScore,
  }}>
    {props.children}
  </ScoreContext.Provider>;
}
