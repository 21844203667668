export { debounce }

const debounce = (fn: Function, delayMs: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return () => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      // timer = null
      // fn.apply(this, arguments)
      fn.apply(globalThis)
    }, delayMs)
  };
}
