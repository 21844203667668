import React, { useEffect, useState } from 'react';
import logo from './images/logo512.png';
import message from './images/message.png';
import './App.scss';
import { LoadingScreen } from './component-loading-screen';
import gsap from 'gsap';
import { FlyingObject } from './components/FlyingObject';
import { playSfxBoing } from './audio/audio';
import { Counter } from './components/Counter';

function App() {
  const [squish, setSquish] = useState(false);
  const [squishMessage, setSquishMessage] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  const rays = Array(10).fill(0);

  const getYear = () : string => {
    return (new Date()).getFullYear().toString();
  }

  useEffect(() => {
    window.onload = () => {
      const hideComplete = () => {
        setLoaded(true);
      }
      gsap.fromTo(".loading-screen", {opacity: 1}
      , {opacity: 0, onComplete: hideComplete}
      ).play();
    }
  }, [])

  return (
    <div className="App">
      {!isLoaded && <LoadingScreen></LoadingScreen>}
      <Counter />
      <div className="flying-object-container">
        {
          rays.map((as, index) => {
            return <FlyingObject key={index} verticalPosition={(index*10).toString() + "%"} />;
          })
        }
      </div>
      <header className="App-header">
        <img src={logo} className={`App-logo ${squish ? "squish" : ""}`} alt="Logo" 
          onClick={() => {
            playSfxBoing(!squish);
            setSquish(true);
          }} onAnimationEnd={() => setSquish(false)}
        />
        <img src={message} className={`App-logo-message ${squishMessage ? "squish" : ""}`} alt="Make life fun!"
          onClick={() => setSquishMessage(true)} onAnimationEnd={() => setSquishMessage(false)} 
        />
        <div className="App-message">
          Life is meant to be fun. What's more fun than making games?... :)<br />
          <p>Contact <a className="App-message-email" href="mailto:infinispeedgames+ws@gmail.com">infinispeedgames+ws@gmail.com</a>
          </p>
        </div>
        {/* <Counter /> */}
        <div className="App-header-bottom">
          <p>©<span id="year">{getYear()}</span> Infinispeed</p>
        </div>
      </header>
    </div>
  );
}

export default App;
