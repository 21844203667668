import React, { useEffect } from 'react';
import './LoadingScreen.scss';
import loadingIcon from './logo192.png';
import * as gsap from 'gsap';

export const LoadingScreen = (): JSX.Element => {
    useEffect(() => {
        gsap.TweenLite.fromTo(`.loading-screen .animation`
        , {rotate: -40}
        , {rotate: 320, duration: 6, ease: gsap.Power0.easeNone})
        .repeat(-1)
        .play();
    });

    return (
        <div className="loading-screen">
            <div className="animation">
                <img src={loadingIcon} alt="loading icon"></img>
            </div>
        </div>
    )
}